import { useQuery } from '@tanstack/react-query';
import { fetchTable } from './api';
import { GrillUser, HookParams } from './types';

export const useGrillPageData = ({
  search,
  sortBy = 'total',
  sortOrder = 'desc',
}: HookParams = {}) => {
  const { data, isLoading, error } = useQuery(['grillTable'], fetchTable);

  const { data: table } = data || {};
  const { values = [] } = table || {};

  const users: GrillUser[] = values
    .slice(6)
    .map((cells) => ({
      name: cells[1],
      big: +cells[122] || 0,
      small: +cells[121] || 0,
      total: +cells[121] + +cells[122] * 3 || 0,
    }))
    .filter((user) => user.name);

  const sortedData = users.sort(
    (a, b) => (a[sortBy] - b[sortBy]) * (sortOrder === 'desc' ? -1 : 1),
  );

  const preparedSearch = search?.toLowerCase();
  const filteredData = preparedSearch
    ? sortedData.filter((user) =>
        user.name.toLowerCase().includes(preparedSearch),
      )
    : sortedData;

  return {
    isUsersLoading: isLoading,
    usersError: error,
    users: filteredData,
  };
};
